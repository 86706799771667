import React from 'react';

const Newsletter = () => {
  return (
    <iframe
      width="540"
      height="550"
      src="https://23d2b345.sibforms.com/serve/MUIFAIb91GKCfne1SBQDKrIew5Zw4N5SNY63XJwT_H0G_ugIM1ODY-UPC5lju88jssxe78Ry9aak-AaHKmo0_aEyM03B0SO1e4tR3BXNg1xje8e46AshDfhs_Xk7rGnnjW_4o04jocr2F9R6_cpKoxaNrK2frUuWfypra8vc3lVBXpykyrIEMfrp0tYPV3nQY-M-GrP933D2i_8y"
      frameBorder="0"
      scrolling="auto"
      allowFullScreen
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '100%',
      }}
    ></iframe>
  );
};

export default Newsletter;
