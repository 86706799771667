import React from 'react';
import './CountryList.css';

import argentinaFlag from './flags/ar.png';
import boliviaFlag from './flags/bo.png';
import brasilFlag from './flags/br.png';
import chileFlag from './flags/cl.png';
import colombiaFlag from './flags/co.png';
import equadorFlag from './flags/ec.png';
import guianaFlag from './flags/gy.png';
import paraguaiFlag from './flags/py.png';
import peruFlag from './flags/pe.png';
import uruguaiFlag from './flags/uy.png';
import venezuelaFlag from './flags/ve.png';

const countries = [
  { name: 'Argentina', code: 'AR', nic: 'https://www.nic.ar', flag: argentinaFlag },
  { name: 'Bolívia', code: 'BO', nic: 'https://www.nic.bo', flag: boliviaFlag },
  { name: 'Brasil', code: 'BR', nic: 'https://registro.br', flag: brasilFlag },
  { name: 'Chile', code: 'CL', nic: 'https://www.nic.cl', flag: chileFlag },
  { name: 'Colômbia', code: 'CO', nic: 'https://www.nic.co', flag: colombiaFlag },
  { name: 'Equador', code: 'EC', nic: 'https://www.nic.ec', flag: equadorFlag },
  { name: 'Guiana', code: 'GY', nic: 'https://registry.gy', flag: guianaFlag },
  { name: 'Paraguai', code: 'PY', nic: 'https://www.nic.py', flag: paraguaiFlag },
  { name: 'Peru', code: 'PE', nic: 'https://www.nic.pe', flag: peruFlag },
  { name: 'Uruguai', code: 'UY', nic: 'https://www.nic.org.uy', flag: uruguaiFlag },
  { name: 'Venezuela', code: 'VE', nic: 'https://www.nic.ve', flag: venezuelaFlag }
];

const CountryList = () => (
  <div className="country-list-container">
    <p>Conheça os órgãos de registro de domínios da América do Sul</p>
    <div className="country-list-wrapper">
      <ul className="country-list">
        {countries.map(country => (
          <li key={country.code} className="country-list-item">
            <a href={country.nic} target="_blank" rel="noopener noreferrer">
              <img
                src={country.flag}
                alt={`${country.name} Flag`}
                className="country-flag"
              />
            </a>
          </li>
        ))}
        {countries.map(country => (
          <li key={country.code} className="country-list-item">
            <a href={country.nic} target="_blank" rel="noopener noreferrer">
              <img
                src={country.flag}
                alt={`${country.name} Flag`}
                className="country-flag"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default CountryList;
