import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getFirestore, collection, getDocs, addDoc, updateDoc, deleteDoc, doc, serverTimestamp, orderBy, query } from 'firebase/firestore';
import './Painel.css';
import { Chart } from "react-google-charts";
import Modal from './Modal'; // Importe o componente Modal
import CadastroDominio from './CadastroDominio'
import WhoisChecker from './WhoisChecker'

const firebaseConfig = {
  apiKey: "AIzaSyA5XOGYD22S7YjZbRcI2eqKIGGQHvLEbWA",
  authDomain: "vitrine-1f820.firebaseapp.com",
  databaseURL: "https://vitrine-1f820-default-rtdb.firebaseio.com",
  projectId: "vitrine-1f820",
  storageBucket: "vitrine-1f820.appspot.com",
  messagingSenderId: "681931464985",
  appId: "1:681931464985:web:845dac350da8766098e588",
  measurementId: "G-WPR64SKNE5"
};

const app = firebase.initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const Painel = () => {
  const [user, setUser] = useState(null);
  const [dominios, setDominios] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [totalValorDominios, setTotalValorDominios] = useState(0);
  const [filtro, setFiltro] = useState('');
  const [resultadosFiltrados, setResultadosFiltrados] = useState([]);
  const [colunaOrdenada, setColunaOrdenada] = useState(null);
  const [ordemAscendente, setOrdemAscendente] = useState(true);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [novoDominio, setNovoDominio] = useState({
    nome: '',
    contato: '',
    titular: '',
    setor: '',
    valor: ''
  });
  const [registroEditando, setRegistroEditando] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [totalClientesFiltrados, setTotalClientesFiltrados] = useState(0);
  const [totalDominiosFiltrados, setTotalDominiosFiltrados] = useState(0);
  const [modalAberto, setModalAberto] = useState(false);
  const [totalSetores, setTotalSetores] = useState(0);
  const [totalClientesBase, setTotalClientesBase] = useState(0);

  const handleMostrarFormulario = () => {
    setMostrarFormulario(prevState => !prevState);
  };

  const handleOrdenar = (coluna) => {
    if (colunaOrdenada === coluna) {
      setOrdemAscendente(!ordemAscendente);
    } else {
      setColunaOrdenada(coluna);
      setOrdemAscendente(true);
    }
  };

  const valorPorCliente = dominios.reduce((acc, cur) => {
    acc[cur.contato] = (acc[cur.contato] || 0) + parseFloat(cur.valor);
    return acc;
  }, {});

  const sortedClientesPorValor = Object.entries(valorPorCliente)
    .sort(([, valorA], [, valorB]) => valorB - valorA)
    .slice(0, 3);

  const dadosClientesPorValor = [['Cliente', 'Valor Total']];
  sortedClientesPorValor.forEach(([cliente, valor]) => {
    dadosClientesPorValor.push([cliente, valor]);
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Buscar e atualizar domínios
        const dominiosSnapshot = await getDocs(collection(firestore, 'dominios'));
        const dominiosData = [];
        const setores = new Set();
        const clientesBase = new Set();

        dominiosSnapshot.forEach((doc) => {
          const dominio = doc.data();
          dominio.id = doc.id;
          dominiosData.push(dominio);
          setores.add(dominio.setor);
          clientesBase.add(dominio.contato);
        });

        setDominios(dominiosData);
        setTotalValorDominios(dominiosData.reduce((acc, cur) => acc + (parseFloat(cur.valor) || 0), 0));
        setTotalSetores(setores.size);
        setTotalClientesBase(clientesBase.size);

        // Buscar e atualizar clientes
        const clientesSnapshot = await getDocs(query(collection(firestore, 'clientes'), orderBy('data')));
        const clientesData = [];
        clientesSnapshot.forEach((doc) => {
          const cliente = doc.data();
          cliente.id = doc.id;
          clientesData.push(cliente);
        });
        setClientes(clientesData);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setFeedback('Erro ao buscar dados. Por favor, tente novamente mais tarde.');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (!user) {
    return <Navigate to="/Login" />;
  }

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      window.location.href = 'https://www.vedo.com.br';
    } catch (error) {
      console.error('Erro ao fazer logout:', error.message);
    }
  };

  const handlePesquisar = () => {
    const termo = filtro.trim().toLowerCase();
    if (termo === '') {
      setResultadosFiltrados([]);
      setTotalClientesFiltrados(0);
      setTotalDominiosFiltrados(0);
      atualizarTotais(dominios);
    } else {
      const resultados = dominios.filter(dominio => {
        return dominio.nome.toLowerCase().includes(termo) ||
          dominio.titular.toLowerCase().includes(termo) ||
          dominio.contato.toLowerCase().includes(termo) ||
          dominio.setor.toLowerCase().includes(termo);
      });
      setResultadosFiltrados(resultados);
      setTotalDominiosFiltrados(resultados.length);

      const clientesFiltrados = clientes.reduce((acc, cliente) => {
        return resultados.some(dominio => dominio.contato === cliente.id) ? acc + 1 : acc;
      }, 0);
      setTotalClientesFiltrados(clientesFiltrados);

      atualizarTotais(resultados);
    }
  };

  const atualizarTotais = (resultados) => {
    const total = resultados.reduce((acc, cur) => acc + (parseFloat(cur.valor) || 0), 0);
    setTotalValorDominios(total);
  };

  const handleLimparFiltro = () => {
    setFiltro('');
    setResultadosFiltrados([]);
    setTotalClientesFiltrados(0);
    setTotalDominiosFiltrados(0);
    atualizarTotais(dominios);
  };

  const handleExcluirDominio = async (id) => {
    if (window.confirm('Tem certeza de que deseja excluir este domínio?')) {
      try {
        await deleteDoc(doc(firestore, 'dominios', id));
        setDominios(prevState => prevState.filter(dominio => dominio.id !== id));
        setResultadosFiltrados(prevState => prevState.filter(dominio => dominio.id !== id));
        setFeedback('Domínio excluído com sucesso.');
      } catch (error) {
        console.error('Erro ao excluir domínio:', error);
        setFeedback('Erro ao excluir domínio. Por favor, tente novamente mais tarde.');
      }
    }
  };

  const handleChangeNovoDominio = (e) => {
    const { name, value } = e.target;
    setNovoDominio(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmitNovoDominio = async (e) => {
    e.preventDefault();
    try {
      if (registroEditando) {
        // Atualiza o documento existente
        await updateDoc(doc(firestore, 'dominios', registroEditando.id), {
          ...novoDominio,
          data: serverTimestamp()
        });
        setDominios(prevState =>
          prevState.map(dominio => (dominio.id === registroEditando.id ? { ...dominio, ...novoDominio } : dominio))
        );
        setFeedback('Domínio atualizado com sucesso.');
      } else {
        // Adiciona um novo documento
        const docRef = await addDoc(collection(firestore, 'dominios'), {
          ...novoDominio,
          data: serverTimestamp()
        });
        setDominios(prevState => [...prevState, { ...novoDominio, id: docRef.id }]);
        setFeedback('Domínio cadastrado com sucesso.');
      }
      setNovoDominio({
        nome: '',
        contato: '',
        titular: '',
        setor: '',
        valor: ''
      });
      setMostrarFormulario(false);
    } catch (error) {
      console.error('Erro ao cadastrar/atualizar domínio:', error);
      setFeedback('Erro ao cadastrar/atualizar domínio. Por favor, tente novamente mais tarde.');
    }
  };

  const handleEditarDominio = (dominio) => {
    setNovoDominio(dominio);
    setRegistroEditando(dominio);
    setMostrarFormulario(true);
  };

  return (
    <div className="painel">
      <h1>Painel de Controle</h1>
      <button onClick={handleLogout} className="btn-logout">Sair</button>
      <div>
        <input
          type="text"
          value={filtro}
          onChange={(e) => setFiltro(e.target.value)}
          placeholder="Pesquisar"
        />
        <button onClick={handlePesquisar} className="btn-pesquisar">OK</button>
        <button onClick={handleLimparFiltro} className="btn-limpar">Limpar Filtro</button>
      </div>

      {mostrarFormulario && (
        <Modal onClose={() => setMostrarFormulario(false)}>
          <CadastroDominio
            novoDominio={novoDominio}
            handleChange={handleChangeNovoDominio}
            handleSubmit={handleSubmitNovoDominio}
            registroEditando={registroEditando}
          />
        </Modal>
      )}

      {feedback && <div className="feedback">{feedback}</div>}

      <div className="tabela">
        <button onClick={handleMostrarFormulario} className="btn-cadastro-dominio">
          {'Cadastrar Novo Domínio'}
        </button>
        <table>
          <thead>
            <tr>
              <th onClick={() => handleOrdenar('nome')}>Domínio {colunaOrdenada === 'nome' ? (ordemAscendente ? '↑' : '↓') : ''}</th>
              <th onClick={() => handleOrdenar('contato')}>Contato {colunaOrdenada === 'contato' ? (ordemAscendente ? '↑' : '↓') : ''}</th>
              <th onClick={() => handleOrdenar('titular')}>Titular {colunaOrdenada === 'titular' ? (ordemAscendente ? '↑' : '↓') : ''}</th>
              <th onClick={() => handleOrdenar('setor')}>Setor {colunaOrdenada === 'setor' ? (ordemAscendente ? '↑' : '↓') : ''}</th>
              <th onClick={() => handleOrdenar('valor')}>Valor {colunaOrdenada === 'valor' ? (ordemAscendente ? '↑' : '↓') : ''}</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {(resultadosFiltrados.length ? resultadosFiltrados : dominios)
              .sort((a, b) => {
                if (colunaOrdenada) {
                  if (ordemAscendente) {
                    return (a[colunaOrdenada] > b[colunaOrdenada]) ? 1 : -1;
                  } else {
                    return (a[colunaOrdenada] < b[colunaOrdenada]) ? 1 : -1;
                  }
                }
                return 0;
              })
              .map(dominio => (
                <tr key={dominio.id}>
                  <td>{dominio.nome}</td>
                  <td>{dominio.contato}</td>
                  <td>{dominio.titular}</td>
                  <td>{dominio.setor}</td>
                  <td>{dominio.valor}</td>
                  <td>
                    <button onClick={() => handleEditarDominio(dominio)} className="btn-editar">Editar</button>
                    <button onClick={() => handleExcluirDominio(dominio.id)} className="btn-excluir">Excluir</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <p>Total Valor Domínios: {totalValorDominios.toFixed(2)}</p>
        <p>Total Clientes: {totalClientesBase}</p>
        <p>Total Domínios Encontrados: {totalDominiosFiltrados}</p>
      </div>

      <div className="grafico">
        <Chart
          width={'500px'}
          height={'300px'}
          chartType="PieChart"
          data={dadosClientesPorValor}
          options={{ title: 'Clientes por Valor Total' }}
        />
      </div>

      <div className="grafico">
        <Chart
          width={'500px'}
          height={'300px'}
          chartType="PieChart"
          data={[
            ['Setor', 'Total'],
            ...Object.entries(dominios.reduce((acc, cur) => {
              acc[cur.setor] = (acc[cur.setor] || 0) + 1;
              return acc;
            }, {}))
          ]}
          options={{ title: 'Distribuição por Setor' }}
        />
      </div>
      <WhoisChecker/>
    </div>
  );
};

export default Painel;
